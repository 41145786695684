import { useApi } from '@infominds/react-api'
import InfomindsAnalytics from '@infominds/react-native-analytics'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import api from '../apis/apiCalls'
import type { PostEmployeeTimeRequest } from '../apis/types/apiRequestTypes'
import type { Document, Employee, EmployeeTime, EmployeeTimeType } from '../apis/types/apiResponseTypes'
import useLastUsedTimes from '../hooks/useLastUsedTimes'
import useUserSettings from '../hooks/useUserSettings'
import DateUtils from '../utils/DateUtils'
import EmployeeUtils from '../utils/EmployeeUtils'
import GeoLocationUtils from '../utils/GeoLocationUtils'
import TimeUtils from '../utils/TimeUtils'
import { useActivities } from './ActivitiesContext'

export interface EmployeeTimeContext {
  currentTime: EmployeeTime | undefined
  isTimeActive: boolean
  todaysTimes: EmployeeTime[]
  getCurrentTime: () => Promise<EmployeeTime | undefined>
  startTime: (activity: EmployeeTimeType, document?: Document, subordinates?: Employee[], note?: string) => Promise<string>
  stopTime: () => Promise<string>
  createTime: (
    date: Date,
    from: number,
    until: number,
    activity: EmployeeTimeType,
    document?: Document,
    subordinates?: Employee[],
    note?: string
  ) => Promise<string>
  getEmployeeTimeTypeById: (id: string) => EmployeeTimeType | undefined
  updateCurrentTime: (note?: string) => Promise<void>
  updateTodaysTimes: () => Promise<EmployeeTime[] | undefined>
  lastTime: EmployeeTime | undefined
}

export const EmployeeTimeContext = createContext<EmployeeTimeContext>({
  currentTime: undefined,
  isTimeActive: false,
  todaysTimes: [],
  getCurrentTime: () => Promise.resolve(undefined),
  startTime: () => Promise.resolve(''),
  stopTime: () => Promise.resolve(''),
  createTime: () => Promise.resolve(''),
  getEmployeeTimeTypeById: () => undefined,
  updateCurrentTime: () => Promise.resolve(),
  updateTodaysTimes: () => Promise.resolve([]),
  lastTime: undefined,
})

export function EmployeeTimeContextProvider({ children }: { children: ReactNode }) {
  const userSettings = useUserSettings()
  const { getActivityById } = useActivities()
  const [todaysTimes, loadTodaysTimes] = useApi(api.getEmployeeTime, [])
  const [currentTime, setCurrenTime] = useState<EmployeeTime | undefined>(undefined)
  const [lastTime, setLastTime] = useState<EmployeeTime | undefined>(undefined)
  const isTimeActive = !!currentTime?.from && !currentTime.until
  const lastUsed = useLastUsedTimes()

  useEffect(() => {
    if (!userSettings) return
    getCurrentTime().catch(console.error)
  }, [userSettings?.employee])

  function getEmployeeTimeTypeById(id: string) {
    return getActivityById(id)
  }

  async function getCurrentTime() {
    if (!userSettings?.employee) return undefined
    await updateTodaysTimes()
    try {
      await loadLastTime()
      const result = await api.getEmployeeTime({ employeeId: userSettings.employee.id, onlyOpenTimes: true, numberOfLastTimes: 1 })
      if (result?.length) {
        const activeTime = result[0]
        //only accept active time if its of today or yesterday
        if (activeTime?.date && DateUtils.differenceInDays(DateUtils.dateify(activeTime?.date), new Date()) <= 1) {
          setCurrenTime(activeTime)
          setLastTime(activeTime)
          return activeTime
        }
      }
      setCurrenTime(undefined)
    } catch (exception) {
      console.error('getCurrentTime Error', exception)
    }
    return currentTime
  }

  async function loadLastTime() {
    if (!userSettings?.employee) return undefined
    const result = await api.getEmployeeTime({ employeeId: userSettings.employee.id, numberOfLastTimes: 1 })
    if (!result?.length) {
      setLastTime(undefined)
      return undefined
    }
    setLastTime(result[0])
    return result[0]
  }

  async function updateTodaysTimes() {
    if (!userSettings?.employee) return undefined
    const date = TimeUtils.getDateForRequest()
    return loadTodaysTimes({
      employeeId: userSettings?.employee.id,
      from: date,
      until: date,
    })
  }

  async function getDevicePosition() {
    try {
      return await GeoLocationUtils.getDevicePosition()
    } catch (exception) {
      console.error(exception)
    }
    return undefined
  }

  async function startTime(activity: EmployeeTimeType, document?: Document, subordinates?: Employee[], note?: string) {
    lastUsed.add({ activity, document })
    const geoLocation = await getDevicePosition()
    const request: PostEmployeeTimeRequest = {
      employeeId: userSettings?.employee.id ?? '',
      date: TimeUtils.getDateForRequest(),
      from: TimeUtils.getCurrentSeconds(),
      employeeTimeTypeId: activity.id,
      documentId: document?.id,
      documentType: document?.documentType,
      pspDetailId: document?.pspDetailId ?? undefined,
      note: note,
      subordinateIds: subordinates?.map(s => s.id),
      latitude: geoLocation?.latitude,
      longitude: geoLocation?.longitude,
    }
    const result = await api.postEmployeeTime(request)
    InfomindsAnalytics.logCustomEvent('new_time')
    await getCurrentTime()
    return result
  }

  async function stopTime() {
    if (!userSettings?.employee) throw 'Invalid Employee in UserSettings'
    const geoLocation = await getDevicePosition()
    const result = await api.stopEmployeeTime({
      employeeId: userSettings?.employee.id,
      date: new Date(),
      until: TimeUtils.getCurrentSeconds(),
      latitude: geoLocation?.latitude,
      longitude: geoLocation?.longitude,
    })
    await getCurrentTime()
    return result
  }

  async function createTime(
    date: Date,
    from: number,
    until: number,
    activity: EmployeeTimeType,
    document?: Document,
    subordinates?: Employee[],
    note?: string
  ) {
    const geoLocation = await getDevicePosition()
    lastUsed.add({ activity, document })
    const result = await EmployeeUtils.createTime(
      userSettings?.employee.id ?? '',
      date,
      from,
      until,
      activity,
      document,
      subordinates,
      note,
      geoLocation?.latitude,
      geoLocation?.longitude
    )
    await getCurrentTime()
    return result
  }

  async function updateCurrentTime(note?: string) {
    if (!currentTime?.id) return
    await api.patchEmployeeTime({
      id: currentTime.id,
      note: note ?? currentTime.note,
    })
    InfomindsAnalytics.logCustomEvent('edit_time')
    await getCurrentTime()
    return
  }

  return (
    <EmployeeTimeContext.Provider
      value={{
        currentTime,
        isTimeActive,
        todaysTimes: todaysTimes ?? [],
        getCurrentTime,
        startTime,
        stopTime,
        createTime,
        getEmployeeTimeTypeById,
        updateCurrentTime,
        updateTodaysTimes,
        lastTime,
      }}>
      {children}
    </EmployeeTimeContext.Provider>
  )
}

export function useEmployeeTime() {
  return useContext(EmployeeTimeContext)
}
